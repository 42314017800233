import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getSavedArtists,
  createSavedArtist,
  reset,
} from "../features/savedArtists/savedArtistSlice";
import { createGoal } from "../features/goals/goalSlice";
import { createSong } from "../features/songs/songSlice";
import SongForm from "./SongForm";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { FaEdit, FaTrashAlt, FaPlus, FaInfoCircle } from "react-icons/fa";

import "react-datepicker/dist/react-datepicker.css";
import ClipLoader from "react-spinners/ClipLoader";

function GoalForm(props) {
  const [text, setText] = useState("");
  const [artist, setArtist] = useState("");
  const [releaseDate, setReleaseDate] = useState(null);
  const [liveDate, setLiveDate] = useState(null);
  const [cLine, setCLine] = useState("");
  const [pLine, setPLine] = useState("");
  const [labelName, setLabelName] = useState("");
  const [numTracks, setNumTracks] = useState(1);
  const [upc, setUpc] = useState("auto-generate");
  const [status, setStatus] = useState("pending approval by team");
  const [coverArt, setCoverArt] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [albumId, setAlbumId] = useState("");
  const [primaryGenre, setPrimaryGenre] = useState(null);
  const [secondaryGenre, setSecondaryGenre] = useState("None");
  const [accountEmail, setAccountEmail] = useState(
    props.user && props.user.email ? props.user.email : ""
  );
  const [accountName, setAccountName] = useState(
    props.user && props.user.name ? props.user.name : ""
  );

  const [artists, setArtists] = useState([
    {
      artistName: "Artist Name",
      spotifyURI: "New Artist",
      appleId: "New Artist",
      role: "primary",
    },
  ]);

  const [writers, setWriters] = useState([
    { writerName: "Writer Name", legalName: "Legal Name" },
  ]);
  const [producers, setProducers] = useState([]);

  const [showSong, setShowSong] = useState(false);

  const [triggerSongSubmit, setTriggerSongSubmit] = useState(0);
  const [validSongCount, setValidSongCount] = useState(0);

  //disable toggle
  const [disableSubmit, setDisableSubmit] = useState(false);

  //good to go file indicator
  const [fileIndicator, setFileIndicator] = useState(false);

  //error file indicator
  const [errorFileIndicator, setErrorFileIndicator] = useState(false);

  //loading indicator
  const [fileLoading, setFileLoading] = useState(false);

  //info circle hover
  const [infoHover, setInfoHover] = useState(false);

  const genreOptions = [
    { value: "Hip-hop/Rap", label: "Hip-hop/Rap" },
    { value: "Alternative", label: "Alternative" },
    { value: "Rock", label: "Rock" },
    { value: "Jazz", label: "Jazz" },
    { value: "Blues", label: "Blues" },
    { value: "Children's", label: "Children's" },
    { value: "Christian", label: "Christian" },
    { value: "Classical", label: "Classical" },
    { value: "Country", label: "Country" },
    { value: "Educational", label: "Educational" },
    { value: "Electronic", label: "Electronic" },
    { value: "Folk", label: "Folk" },
    { value: "Holiday", label: "Holiday" },
    { value: "Latin Music", label: "Latin Music" },
    { value: "Metal", label: "Metal" },
    { value: "New Age", label: "New Age" },
    { value: "Pop", label: "Pop" },
    { value: "Punk", label: "Punk" },
    { value: "R&B", label: "R&B" },
    { value: "Reggae", label: "Reggae" },
    { value: "Soundtracks", label: "Soundtracks" },
    { value: "Spoken Word", label: "Spoken Word" },
    { value: "Video", label: "Video" },
    { value: "World Music", label: "World Music" },
  ];

  const genreSubgenres = {
    Alternative: [
      "Indie Pop",
      "Pop Punk",
      "Alternative",
      "Indie Rock",
      "Grunge",
      "New Wave",
      "Punk",
      "Emo",
    ],
    Blues: [
      "Jump-Blues",
      "Juke Joint Blues",
      "Boogie Woogie",
      "Blues Rock",
      "Memphis Blues",
      "Inspirational",
      "Electric Blues",
      "Soul",
      "Contemporary Blues",
      "Urban Blues",
      "Country Blues",
      "Harmonica Blues",
      "Blues",
      "Traditional Blues",
      "Texas Blues",
      "Piano Blues",
      "Blues Revival",
      "Dirty Blues",
      "Classic Blues",
      "New Orleans R&B",
      "Pre-War",
      "Work Songs",
      "Louisiana Blues",
      "Delta Blues",
      "Swamp Blues",
      "Soul / R&B",
      "Chicago Blues",
      "Swing",
      "Piedmont Blues",
      "Gospel",
      "Acoustic Blues",
      "Vaudeville Blues",
      "Songster",
    ],
    "Children's": [
      "Children's",
      "Classical",
      "Disney",
      "Educational",
      "Stories",
      "Religious",
      "Other",
      "Lullabies",
      "Sing-Along",
      "Ancient Tales",
      "Circus",
    ],
    Christian: [
      "Traditional Gospel",
      "Christian Metal",
      "Gospel",
      "Spoken Word",
      "Christian Rock",
      "Christian Pop",
      "Other",
      "Gospel Choir",
      "Christian Punk",
      "Christian",
      "Ska",
      "Praise & Worship",
      "Christian Country",
      "Christian Rap",
      "Instrumental Gospel",
      "CCM",
      "Southern Gospel",
      "Contemporary Gospel",
    ],
    Classical: [
      "High Classical",
      "Gregorian",
      "Crossover",
      "Impressionist",
      "Symphony",
      "Postmodern",
      "Medieval/ Renaissance",
      "Electronic/Avant-Garde/Minimalis",
      "Opera",
      "Modern Composition",
      "Orchestral Music",
      "Musical Theater",
      "Avant-garde Classical",
      "Baroque",
      "Classical Vocal",
      "Film Music",
      "Pre-Baroque",
      "Choral Music",
      "Other",
      "Wedding Music",
      "Chamber Music",
      "Band Music",
      "Classical Sacred",
      "Romantic",
      "Ballet",
      "Early",
      "Guitar",
      "20th/21st Century",
      "Classical Crossover",
      "Concerto",
      "Keyboard Music",
      "Classical",
      "Zarzuela",
      "Vocal",
    ],
    Country: [
      "Instrumental Country",
      "Country Gospel",
      "Country",
      "Neo-Traditionalist",
      "Other",
      "Americana",
      "Yodeling",
      "Country Boogie",
      "Western Swing",
      "Alt. Country",
      "Bluegrass",
      "Classic Country",
      "Traditional Country",
      "Progressive",
      "Urban Cowboy",
      "Nashville Sound/Countrypolitan",
      "Rockabilly",
      "Honky-Tonk",
      "Country Folk",
      "Live Country",
      "Country Rock",
      "Old-Timey",
      "Outlaw",
      "Country-Pop",
      "Cowboy",
      "String Bands",
      "Contemporary Bluegrass",
      "Compilations",
      "Contemporary Country",
      "Modern Country",
      "Country Singer/Songwriter",
    ],
    Educational: ["Educational"],
    Electronic: [
      "Downbeat",
      "Techno Dark",
      "Hard House",
      "Indie Dance / Nu Disco | Indie Dance",
      "Nu Breaks",
      "Euro-Dance",
      "Progressive House",
      "Industrial",
      "Melodic Techno",
      "Break Beat",
      "Breaks",
      "Remix",
      "140 / Deep Dubstep / Grime",
      "Abstract",
      "Amapiano",
      "Electroclash",
      "Indie Dance / Nu Disco | Nu Disco",
      "Jackin House",
      "Trip-Hop",
      "Hard Techno",
      "Space",
      "Downtempo",
      "Psytrance",
      "Trance (Raw / Deep / Hypnotic)",
      "Ambient",
      "Groove",
      "Rave",
      "Psychedelic",
      "Newbeat",
      "Techno Dub",
      "2-step/British Garage",
      "Funky Breaks",
      "Tribal",
      "Tech-House",
      "Minimal Techno",
      "Crossover",
      "Gloom Wave",
      "Big Beat",
      "Hard Dance",
      "Big Room",
      "Nu-Jazz/Broken Beat",
      "Krautrock",
      "Bass / Club",
      "Electronic",
      "Teknobody",
      "Trance (Main Floor)",
      "Happy Hardcore",
      "Dark Wave",
      "Wave",
      "Dark",
      "Club/Dance",
      "Freestyle",
      "Dubstep",
      "NuDisco / Disco",
      "House",
      "Melodic House",
      "Hi-NRG",
      "Euro-Beat",
      "Bass House",
      "Electro",
      "Tribal House",
      "Lounge",
      "DJ Mix",
      "Fitness & Workout",
      "Global Groove",
      "Digital Hardcore",
      "Indie Dance / Nu Disco",
      "I.D.M.",
      "Electro House",
      "Techno",
      "Deep House",
      "Gabba",
      "Compilations",
      "Afro house",
      "Electro Dark",
      "Dance-Pop",
      "Drum'n'Bass / Jungle",
      "DJ",
      "Illbient",
      "Trap",
      "Post-Rock",
      "Future Pop-Synth",
      "Electronic Dub",
      "Experimental",
      "DJ Tools",
      "Other",
      "Trance",
      "Techno Clash",
      "Glitch",
      "Electronic 12-inches",
      "Avant-garde",
      "Organic House",
      "Acid Jazz",
      "Dance",
      "Ambient Dub",
      "Chill",
      "Future House",
    ],
    Folk: [
      "German Folk",
      "Scottish Folk",
      "Medieval",
      "Other",
      "Folk Singer/Songwriter",
      "Contemporary",
      "Live Folk",
      "New Acoustic",
      "Anti-Folk",
      "Bluegrass",
      "Gypsy",
      "Folk-Rock",
      "Mediterranean",
      "Drinking Songs",
      "Traditional Folk",
      "Progressive Folk",
      "Sardana",
      "Dark",
      "Sea Shanties",
      "Old Time",
      "Flamenco",
      "Jug Bands",
      "Folk Revival",
      "Alternative Folk",
      "Field Recordings",
      "Folk",
      "Historical",
      "Skiffle",
      "Contemporary Folk",
      "Spoken Word",
      "Acoustic",
      "Jota",
      "Sepharad",
      "Political/Protest",
      "Chassidic",
    ],
    "Hip-hop/Rap": [
      "Bass",
      "New School",
      "Party Rap",
      "Foreign Language Rap",
      "Hip-Hop",
      "Funk",
      "Gangsta",
      "Electro",
      "British Rap",
      "Old School",
      "Beats & Breaks",
      "Rap",
      "Conscious/Political",
      "Neosoul",
      "A Cappellas",
      "Instrumentals",
      "Other",
      "West Coast",
      "Comedy Rap",
      "Electro-Funk",
      "Dirty South/Crunk",
      "New Jack Swing",
      "East Coast",
      "Live Urban/Hip-Hop",
      "Turntablism",
      "Contemporary R&B",
      "Latin",
      "Hip-Hop 12-inches",
      "Alternative Rap",
      "Hardcore Rap",
      "Underground",
      "R&B",
    ],
    Holiday: [
      "Halloween",
      "Christmas: Classical",
      "Kwaanza",
      "Christmas: Children's",
      "Thanksgiving",
      "Christmas: Singer-Songwriter",
      "Christmas: Jazz",
      "Christmas: Country",
      "Others",
      "Christmas: Religious",
      "Jewish",
      "Muslim",
      "Christmas: R&B",
      "Christmas",
      "Christmas: Pop",
      "Christmas: Modern",
      "Zorastrian",
      "Holiday",
      "Christmas: Rock",
      "Christmas: Classic",
      "Buddhist",
      "Easter",
      "Hanukkah",
      "Hindu",
      "Christian",
    ],
    Jazz: [
      "Swing",
      "Progressive Jazz",
      "Lounge",
      "Crossover",
      "Cuban Jazz",
      "Trad Jazz",
      "Fusion",
      "Smooth Jazz",
      "Avant-garde Jazz",
      "New Orleans Jazz",
      "Jazz/Blues",
      "Other",
      "Lounge Jazz",
      "Classic Jazz",
      "Orchestral Jazz",
      "African Jazz",
      "Jive",
      "Big Band",
      "Bop",
      "Free Jazz",
      "Hard Bop",
      "Post-Bop",
      "Funk",
      "Klezmer",
      "Latin Jazz",
      "Ragtime",
      "Afro-Cuban Jazz",
      "BeBop",
      "Brazilian Jazz",
      "Dixieland",
      "Marching Bands",
      "Live Jazz",
      "Easy Listening",
      "Cool Jazz",
      "Electronic",
      "Soul Jazz",
      "Cabaret",
      "Acid Jazz",
      "Contemporary",
      "Brass Bands",
      "Vocal Jazz",
      "Flamenco",
      "Mainstream Jazz",
      "Jazz",
      "World Fusion",
      "Contemporary Jazz",
    ],
    Latin: [
      "Pop Rock Latino",
      "Ranchero",
      "Nuevo Flamenco",
      "Fado",
      "Samba",
      "Afro-Cuban",
      "Rumba",
      "Danzon",
      "Afro-Brazilian",
      "Can",
      "Guajira",
      "Bossa Nova",
      "candombe",
      "Latin Soul",
      "Cumbia",
      "Joropo",
      "Vallenato",
      "Toque",
      "Forro",
      "Banda",
      "Jibaro",
      "Bomba",
      "Mariachi",
      "Timba",
      "Rock en Espa",
      "Latin Rap",
      "Latin Jazz",
      "Jarabe",
      "Caribbean",
      "Guaganco",
      "Electronic",
      "Salsa",
      "Big Band Latino",
      "Trova",
      "Norte",
      "Bolero",
      "Latin Folk",
      "Other",
      "Carribbean",
      "Copla",
      "Pachanga",
      "Bachata",
      "Tango",
      "Cuatro",
      "Latin EDM",
      "Latin Pop",
      "Nueva Canci",
      "Reggaeton",
      "Latin",
      "Mambo",
      "Huapango",
      "Cantautor",
      "Argentine Folklore",
      "Merengue",
      "Brazilian",
      "Italian",
      "Flamenco",
      "Son",
      "Contemporary Latin",
      "Afro-Peruvian",
      "Tropicalia",
      "Musica Popular Brasileira (MPB)",
      "Novo Fado",
      "Grupero",
      "Chotis",
      "Plena",
      "Redova",
      "Spanish",
      "Quechua",
      "Cha Cha Cha",
      "Conjunto",
      "Tejano",
    ],
    Metal: [
      "Hair Band",
      "Symphonic Metal",
      "Metalcore",
      "Stoner Rock",
      "Speed",
      "Heavy",
      "Doom",
      "Black",
      "Death",
      "Guitar Virtuoso",
      "Grindcore",
      "Power Metal",
      "Sludge",
      "Alternative",
      "Scandinavian",
      "Thrash",
      "Metal",
      "Glitter",
      "Emo Metal",
      "Teenager",
      "Pop-Metal",
      "Goth-Metal",
      "Instrumental",
      "Other",
      "Industrial Metal",
      "Rap Metal",
      "British Metal",
    ],
    Ambient: [
      "Ambient/Atmospheres",
      "New Age",
      "Ethnic Fusion",
      "Meditation/Relaxation",
      "Progressive",
      "Nature",
      "Relaxation",
      "Environmental",
      "Sacred",
      "Neo-Classical",
      "Self-Help",
      "Solo Instrumental",
      "Minimalism",
      "World",
      "Cartoon Music",
      "Healing",
      "Contemporary Instrumental",
      "Electro-Acoustic",
      "Vocal",
      "Spiritual",
      "Other",
    ],
    Pop: [
      "Traditional Pop",
      "Teen Pop",
      "Chamber Pop",
      "A Cappella",
      "Parody",
      "Girl Group",
      "K-Pop",
      "Bubble Gum",
      "Female Vocalist",
      "Instrumental",
      "Dance-Pop",
      "Power Pop",
      "Pop",
      "Male Vocalist",
      "Standards",
      "Indie Pop",
      "Brit-Pop",
      "Christian Music",
      "German Pop",
      "Pop Singer/Songwriter",
      "Euro-Pop",
      "Diva",
      "Dream Pop",
      "Other",
      "Disco",
    ],
    Punk: [
      "L.A. Punk",
      "Digital Hardcore",
      "Punkabilly",
      "Alt/Punk Ska",
      "Oi!",
      "Live Alt/Punk",
      "International Punk",
      "New York Punk",
      "No Wave",
      "Emo Punk",
      "Ska-Punk",
      "Political Punk",
      "Riot Girl",
      "Other",
      "Skate-Punk",
      "Straight Edge",
      "Hardcore",
      "Pop-Punk",
      "Punk",
      "Anarchist",
      "Industrial",
      "Queercore",
      "Cowpunk",
      "Brit-Punk",
      "Post-Punk",
    ],
    Soul: [
      "Gospel",
      "Motown",
      "Funk",
      "Other",
      "Soul",
      "Doo Wop",
      "Diva",
      "Neo-Soul",
    ],
    Reggae: [
      "Reggae",
      "Other",
      "Dancehall",
      "Dub",
      "Roots Reggae",
      "Soca",
      "Reggae Gospel",
      "Ska",
      "Ragga",
      "Lovers Rock",
      "Calypso",
      "Political Reggae",
      "Rocksteady",
    ],
    Rock: [
      "Jam Rock",
      "Indie Rock",
      "Arena Rock",
      "Instrumental Rock",
      "Jam Band",
      "Grunge",
      "Rockabilly",
      "Krautrock",
      "Classic Rock",
      "Southern Rock",
      "Pop-Rock",
      "Country-Rock",
      "Roots Rock",
      "Urbano",
      "New Wave",
      "Drone",
      "Alternative",
      "80's",
      "Oldies",
      "Lo-fi",
      "Contemporary Singer/Songwriter",
      "Psychobilly",
      "Noise-Rock",
      "American Trad Rock",
      "Commercial Alternative",
      "Math Rock",
      "Post-Rock / Experimental",
      "Proto-Punk",
      "Adult Contemporary",
      "Americana",
      "Adult Alternative",
      "Boogie Rock",
      "Other",
      "Radical",
      "Psychedelic Rock",
      "Surf",
      "Rock Singer/Songwriter",
      "Folk Rock",
      "Industrial Rock",
      "British Invasion",
      "Tex-Mex",
      "Modern Rock",
      "Funk Rock",
      "College Rock",
      "Goth",
      "Progressive",
      "Glam Rock",
      "Garage Rock",
      "Hard Rock",
      "Rock 'n' Roll",
      "Brit-Pop",
      "Power-Pop",
      "Duro",
      "Soft Rock",
      "Rock",
      "Prog-Rock/Art Rock",
      "Post-Punk",
      "Live Rock",
      "Foreign Language Rock",
    ],
    Soundtracks: [
      "Soundtracks",
      "Original Score",
      "Other Environmental",
      "Marching Band",
      "Video Game",
      "Other",
      "Radio",
      "Holiday",
      "Spoken Word",
      "Film Soundtracks",
      "Children's",
      "Sound Effects",
      "Comedy (Soundtracks)",
      "Theatre Scores",
      "Vocal Pop",
      "Anime",
      "TV Soundtracks",
    ],
    "Spoken Word": [
      "Spoken Word",
      "Radio Comedy",
      "Novelty",
      "Blue Humor",
      "Poetry",
      "Musical Comedy",
      "Comedy (Spoken Word)",
      "Political Humor",
      "Self-Help",
      "Gay Comedy",
      "Lectures",
      "Sketch Comedy",
      "Satire",
      "Standup Comedy",
      "Christian Comedy",
      "Observational Humor",
      "Prank Calls",
      "Ethnic Comedy",
      "Lectures",
      "Sketch Comedy",
      "Satire",
      "Standup Comedy",
    ],
    "TV & Movies": [
      "Educational & Learning",
      "African American",
      "Adult",
      "International",
      "Military & War",
      "Music & Performing Arts",
      "Holiday",
      "Biography",
      "Gay & Lesbian",
      "Drama",
      "Sports",
      "Health and Fitness",
      "Independent",
      "Spanish Language",
      "Reality",
      "Game Show",
      "Comedy",
      "Mystery",
      "Religon and Spirituality",
      "Documentary",
      "New Age & Metaphysics",
      "Kids & Family",
      "Art House",
      "Romance",
      "Classics",
      "News",
      "Video",
      "Horror",
      "Concert Footage",
      "Science Fiction & Fantasy",
      "Politics",
      "Western",
      "Animation",
      "Martial Arts",
      "History",
      "Variety Show",
      "Talk Show",
      "Action & Adventure",
      "Teens",
      "Thriller",
    ],
    "World Music": [
      "Rock",
      "Russian Religious Ringing of Bel",
      "Nordic",
      "Russian Religious Valaam Song Si",
      "Bangladeshi - Classical",
      "Indian",
      "Spain - Galician",
      "Peru",
      "Brazilian-Boi Bumba",
      "Spain - Castilian",
      "Chinese Singer/Songwriter",
      "Indo-Pop",
      "Indian - Malayalam Film",
      "Irish",
      "Brazilian-Classical",
      "Indian Carnatic Classical",
      "Russian Rock",
      "Chinese Traditional Opera",
      "Indian - Kannada Film",
      "Brazilian-Christian",
      "Turkish Punk",
      "Brazilian-Bossa Nova",
      "Afrobeats",
      "Amapiano",
      "Russian Banner Singing",
      "Korean Folk-Pop",
      "Nepalese - Pop & Fusion",
      "Afrikaans",
      "Sinhalese - Film",
      "Turkish Standup",
      "Bangladeshi - Devotional",
      "Indian Gazal",
      "Russian Military Marches",
      "Brazilian-Children's",
      "Brazilian-Funk",
      "Variete Francaise",
      "Sinhalese - Classical",
      "Indian Carnatic Classical Instrumental",
      "Regional Indian",
      "Korean Trad Theater",
      "Europe",
      "Turkish Poetry",
      "Japanese Pop",
      "Russian Pop",
      "Chinese Comedy",
      "Chinese Local Opera",
      "KayoKyoku",
      "Russian Opera",
      "Turkish Arabesque",
      "Russian Operetta",
      "Russian Church Song Singing",
      "Thai Pop",
      "Chinese Hip-Hop",
      "Western European",
      "Brazilian-Brega",
      "Russian Orthodox Song Singing",
      "Islamic",
      "Cantopop",
      "Russian Folk-Cossacks' singing",
      "Indian - Marathi Film",
      "Indian - Telugu",
      "Scandinavian",
      "Turkish Electronic",
      "Fado",
      "Chinese Classical",
      "Nepali - Film",
      "New Age",
      "Arabic Pop",
      "North African",
      "Punk",
      "Pakistani - Classical",
      "Brazilian-MPB",
      "Dangdut",
      "Arabic Classic",
      "Greece",
      "Brazilian-Forró",
      "Brazilian-Afro",
      "Brazilian Rock",
      "Alternative",
      "Indian Devotional & Spiritual",
      "Brazilian-Jovem Guarda",
      "Comedy (World)",
      "Inspirational",
      "Turkish Metal",
      "Malaysian Pop",
      "Celtic",
      "Jewish Folk",
      "Brazilian-Spoken Word",
      "Sinhalese - Devotional",
      "Russian Shanson-Thieves Slang",
      "Russian Folk-Chastooshkas",
      "Brazilian-Jazz",
      "Brazilian-Candomble",
      "Indian Bollywood",
      "Chinese Orchestral",
      "Turkish Ashik Songs",
      "Indian Sufi",
      "Korean Hip-Hop",
      "Indian Regional Film",
      "Indonesian Religious",
      "Klezmer",
      "Turkish Blacksee Reg.",
      "Cap-verde",
      "Reggae",
      "Pacific Islands",
      "Russian Folk",
      "Contemporary Celtic",
      "Japan",
      "Bangladeshi - Pop & Fusion",
      "Country-Rock",
      "Brazilian-Lambada",
      "Israeli",
      "K-Pop",
      "Middle Eastern",
      "Indian - Malayalam",
      "Asturias",
      "Chinese Strings",
      "Zydeco",
      "Foreign Language Rock",
      "Mediterranean",
      "Brazilian-Samba/Pagode",
      "Sinhalese - Pop & Fusion",
      "Arabic Belly-Dance",
      "Indian - Gujarati",
      "Indian - Hindi Film",
      "Belgian",
      "Brazilian-Mangue Beat/Pernambuco",
      "South American",
      "Southeast Asian",
      "Korean Rock",
      "Korean Classical",
      "Romanian",
      "Chinese Rock",
      "World Fusion",
      "Caribbean",
      "Indian - Marathi",
      "Russian Symphonic",
      "Russian Bard Songs",
      "Indian - Gujarati Film",
      "Turkish Islamic And Sufi",
      "Indian - Tamil",
      "Indian - Fusion",
      "Frevo",
      "Indian - Konkani",
      "Spoken Word",
      "Brazilian-Gaucho",
      "Greek",
      "Taiwanese Folk",
      "Brazilian-Caipira",
      "Nepalese - Devotional",
      "Brazilian Blues",
      "Turkish Hip Hop",
      "Mandopop",
      "Tai-Pop",
      "Russian WWI Songs",
      "Russian Church Choir Singing",
      "Brazil",
      "Indian Hindustani Classical",
      "Scottish",
      "Brazilian-Maracatu",
      "Brazilian-Funk Carioca",
      "France",
      "Russian Estrada",
      "Turkish Children's",
      "Indian Hindustani Classical Instrumental",
      "Turkish Dini",
      "Brazilian-Arrocha",
      "Korea",
      "Turkish Islamic Spoken",
      "Indian Tamil Film",
      "Indian - Oriya Film",
      "Chinese Spoken Word",
      "Brazilian Pop",
      "Chants",
      "Flamenco/ Tango",
      "Indian - Bengali",
      "Russian National Music-Lezginka",
      "Arabic Folklore",
      "Pakistan",
      "Pakistani - Pop & Fusion",
      "Swiss",
      "Central Asian",
      "African Pop",
      "Brazilian-Regional",
      "Indian - Bengali Film",
      "Chinese Folk",
      "Indian - DJ",
      "International Pop",
      "Indian Telugu Film",
      "Arabic Rock",
      "Raices",
      "Metal",
      "Indian Pop & Fusion",
      "Turkish Instrumental",
      "Soundtracks",
      "Eastern Europe",
      "Spain",
      "Russian Alternative",
      "Turkish Traditional",
      "Bangladeshi - Film",
      "Indian - Punjabi Film",
      "Russian Folk-Cossacks' chorus si",
      "Pan Global",
      "Other",
      "Gamelan",
      "Turkish Halk",
      "Indigenous",
      "Chinese Flute",
      "Tibetan Native Music",
      "Nepalese - Folk",
      "Iberia",
      "Khaleeji",
      "Peking Opera",
      "Indian - Bhangra",
      "Enka",
      "Indian - Oriya",
      "Farsi",
      "Chinese Instrumental",
      "Sinhalese - Folk",
      "Far Eastern",
      "Russian National Music-Tyva Gutt",
      "Korean Trad Instrumental",
      "Brazilian-Tecnobrega",
      "Turkish World",
      "Russian Folk-Russian humour",
      "Regional Mexicano",
      "Indian - Assamese",
      "Pakistani - Folk",
      "Pakistani - Film",
      "Pakistani - Devotional",
      "Russian Folk-Russian gypsy songs",
      "Russian Revolution Songs",
      "Italy",
      "Castilla",
      "Pop",
      "Spain - Asturias",
      "Korean Trad Song",
      "Chinese",
      "Brazilian-Axe",
      "Chinese Soundtrack",
      "Turkish Soundtrack",
      "Turkish Jazz",
      "South Pacific",
      "Brazilian-Sertaneja",
      "Folk",
      "Turkish Techno",
      "Galicia",
      "Cajun",
      "Turkish Tango",
      "Turkish Dance",
      "Jazz",
      "Brazilian Metal",
      "J-Rock",
      "Chinese Vocalist",
      "Australian",
      "Indian - Punjabi",
      "Arabic Romantic",
      "Russian Folk-Russian romances",
      "Mexico",
      "Hawaii",
      "Indian - Kannada",
      "Thai Country",
      "Rai",
      "R&B",
      "Brazilian Reggae",
      "Manilla Sound",
      "Mestizo",
      "South Africa",
      "Turkish Folk",
      "Trot",
      "Portugal",
      "Soukos",
      "Turkish Oriental",
      "Morocco",
      "Hip/Hop Rap",
      "Chinese R&B",
      "Brazilian Hip Hop",
      "Traditional Celtic",
      "Russian Valaam Song Singing",
      "Electronic",
      "Turkish Sanat",
      "Original Pilipino Music",
      "Pinoy Pop",
      "North America",
      "Indian - Hindi",
      "Brazilian Soul",
      "Brazilian-Choro",
      "Indonesia",
      "Russian Folk-Weeping",
      "Russian National Music-Tatar Fol",
      "Turkish Classical",
      "Brazilian-Instrumental",
      "Russian Military Songs",
      "Turkish Rock",
      "World Compilations",
      "Salsa",
      "Afrobeat",
      "Turkish New Age",
      "Turkish Trip Hop",
      "Russian National Music-Buryat Gu",
      "World Beat",
      "Russian Hard-rock",
      "Central European",
      "Choro",
      "Arabic Dance",
      "Bangladeshi - Folk",
      "Electronica",
      "Classical",
      "Blues",
      "Brazilian-Baião",
      "African",
      "Polka",
      "Folklore",
      "Arabic Children",
      "Turkish Pop",
      "Indian Folk",
      "Native American",
      "Korean Indie",
      "Russian Chanson",
      "Indian - Hindi Non-Film",
      "Scandinavian Dansband",
      "Nepalese - Classical",
      "Indian - Assamese Film",
      "C-Pop",
      "Basque",
      "Cuba",
      "Romanian Manele",
      "Celtic Folk",
      "Indian Classical",
      "Social Commentary",
      "Brazilian-Tropicalia",
      "Sertanejo",
      "World Music",
      "Latin",
      "Asia",
      "Chinese Children's",
      "Russian Great Patronimic War Son",
      "Pakistani - Sufi & Gazal",
      "Chinese Pop",
      "Chinese Electrical",
      "Brazilian-Capoeira",
      "International Folk",
      "Chinese Alternative",
      "Russian Folk-Feast songs",
    ],
  };

  const [secondaryGenreOptions, setSecondaryGenreOptions] = useState([]);

  useEffect(() => {
    console.log("Primary Genre: ", primaryGenre);
    if (primaryGenre && genreSubgenres[primaryGenre.value]) {
      const subgenres = genreSubgenres[primaryGenre.value];
      const formattedSubgenres = subgenres.map((subgenre) => ({
        value: subgenre,
        label: subgenre,
      }));
      console.log("Formatted Subgenres: ", formattedSubgenres);
      setSecondaryGenreOptions(formattedSubgenres);
    } else {
      setSecondaryGenreOptions([]);
    }
  }, [primaryGenre]);

  const artistRoleOptions = [
    { value: "Primary", label: "Primary" },
    { value: "Featured", label: "Featured" },
  ];

  const [songs, setSongs] = useState([]);

  // Initialize song data structure when number of songs changes
  const handleNumSongsChange = (newNum) => {
    // const newNum = Number(event.target.value);
    // setNumTracks(newNum);

    // Initialize the songs array with sequential songNumber values
    const newSongs = Array.from({ length: newNum }, (_, index) => ({
      songName: "",
      isrc: "auto-generate",
      previewStartTime: "None",
      songNumber: index + 1, // Track position starts at 1
      album: "",
      artists: artists,
      writers: writers,
      producers: producers,
      audioUrl: "",
      payeeInfo: {
        isEntered: false,
        payees: [],
      },
      explicit: false,
    }));

    setSongs(newSongs);
  };

  //useEffect that updates the song data structure when the number of songs changes
  useEffect(() => {
    // Initialize the songs array with sequential songNumber values
    // const newSongs = Array.from({ length: numTracks }, (_, index) => ({
    //   songName: "",
    //   isrc: "auto-generate",
    //   previewStartTime: "None",
    //   songNumber: index + 1, // Track position starts at 1
    //   album: "",
    //   artists: artists,
    //   writers: writers,
    //   producers: producers,
    //   audioFile: null,
    // }));
    handleNumSongsChange(numTracks);
  }, [numTracks]);

  // Update song data
  const handleSongChange = (index, songData) => {
    const newSongs = [...songs];
    newSongs[index] = songData;
    console.log("newSongs: ", newSongs);
    setSongs(newSongs);
  };

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { savedArtists, isLoading, isError, message } = useSelector(
    (state) => state.savedArtists
  );

  useEffect(() => {
    console.log("Valid Song Count: ", validSongCount);
  }, [validSongCount]);

  useEffect(() => {
    console.log("artists: ", artists);
  }, [artists]);

  let artistOptions = savedArtists.map((artist) => ({
    value: artist._id,
    label: artist.artistName,
  }));

  useEffect(() => {
    if (isError) {
      console.log(message);
      //localStorage.clear();
    }

    if (!user) {
      /*setTimeout(() => {
        console.log("World!");
      }, 5000);*/
      //navigate("/login");
      console.log("yeetDash");
    }

    if (user) {
      dispatch(getSavedArtists());
      artistOptions = savedArtists.map((artist) => ({
        value: artist._id,
        label: artist.artistName,
      }));
    }

    return () => {
      dispatch(reset());
    };
  }, [user]);

  /*useEffect(() => {
    console.log(albumId);
  }, []);*/

  const incrementCount = () => {
    setValidSongCount(validSongCount + 1);
  };

  const decrementCount = () => {
    setValidSongCount(validSongCount - 1);
  };

  /*const handleImage = (e) => {
    setCoverArt(e.target.files[0]);
  };*/

  function addWriter() {
    setWriters([
      ...writers,
      {
        writerName: "Writer Name",
        legalName: "Legal Name",
      },
    ]);
  }

  function deleteWriter(index) {
    const updatedWriters = [...writers];
    updatedWriters.splice(index, 1);
    setWriters(updatedWriters);
  }

  function handleWriterNameChange(e, index) {
    const updatedWriters = [...writers];
    updatedWriters[index].writerName = e.target.value;
    setWriters(updatedWriters);
  }

  function handleWriterLegalNameChange(e, index) {
    const updatedWriters = [...writers];
    updatedWriters[index].legalName = e.target.value;
    setWriters(updatedWriters);
  }

  function addProducer() {
    setProducers([
      ...producers,
      {
        producerName: "Producer Name",
      },
    ]);
  }

  function deleteProducer(index) {
    const updatedProducers = [...producers];
    updatedProducers.splice(index, 1);
    setProducers(updatedProducers);
  }

  function handleProducerNameChange(e, index) {
    const updatedProducers = [...producers];
    updatedProducers[index].producerName = e.target.value;
    setProducers(updatedProducers);
  }

  function addArtist() {
    setArtists([
      ...artists,
      {
        artistName: "Artist Name",
        spotifyURI: "New Artist",
        appleId: "New Artist",
        role: "primary",
      },
    ]);
  }

  function deleteArtist(index) {
    const updatedArtists = [...artists];
    updatedArtists.splice(index, 1);
    setArtists(updatedArtists);
  }

  function handleArtistNameChange(e, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].artistName = e.target.value;
    setArtists(updatedArtists);
  }

  function handleSpotifyURIChange(e, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].spotifyURI = e.target.value;
    setArtists(updatedArtists);
  }

  function handleAppleIdChange(e, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].appleId = e.target.value;
    setArtists(updatedArtists);
  }

  function handleRoleChange(selectedOption, index) {
    const updatedArtists = [...artists];
    updatedArtists[index].role = selectedOption.value;
    setArtists(updatedArtists);
  }

  function handleArtistSelect(selectedOption, index) {
    const filteredArray = savedArtists.filter((obj) => {
      return obj._id.toString() == selectedOption.value;
    });
    const updatedArtists = [...artists];
    updatedArtists[index].artistName = filteredArray[0].artistName;
    updatedArtists[index].role = filteredArray[0].role;
    updatedArtists[index].spotifyURI = filteredArray[0].spotifyURI;
    updatedArtists[index].appleId = filteredArray[0].appleId;
    setArtists(updatedArtists);
  }

  function saveArtist(art) {
    //const updatedArtists = [...artists];
    //updatedArtists[index].role = selectedOption.value;
    //setArtists(updatedArtists);
    const artistName = art.artistName;
    const role = art.role;
    const spotifyURI = art.spotifyURI;
    const appleId = art.appleId;
    dispatch(
      createSavedArtist({
        artistName,
        role,
        spotifyURI,
        appleId,
      })
    );
    dispatch(reset());
    dispatch(getSavedArtists());
    artistOptions = savedArtists.map((artist) => ({
      value: artist._id,
      label: artist.artistName,
    }));

    toast("Artist info saved!");
  }

  const handleImage = async (e) => {
    setFileLoading(true);
    const file = e.target.files[0];
    //check the file type
    if (file.type !== "image/jpeg" && file.type !== "image/jpg") {
      alert("Please upload a JPG file");
      setImageUrl(null);
      setFileIndicator(false);
      setErrorFileIndicator(true);
      setFileLoading(false);
      return;
    }
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;
      image.onload = async () => {
        const width = image.width;
        const height = image.height;
        const fileType = file.type;
        const fileSize = file.size;
        const validFileType = ["image/jpeg", "image/jpg"].includes(fileType);
        const validDimensions = width === 3000 && height === 3000;
        const validFileSize = fileSize <= 10000000;

        if (!validFileType) {
          alert("Please upload a JPG file");
          setImageUrl(null);
          setFileIndicator(false);
          setErrorFileIndicator(true);
          setFileLoading(false);
          return;
        }

        if (!validDimensions) {
          alert("Please upload an image with dimensions of 3000x3000");
          setImageUrl(null);
          setFileIndicator(false);
          setErrorFileIndicator(true);
          setFileLoading(false);
          return;
        }

        if (!validFileSize) {
          alert("Please upload an image that is under 10MB");
          setImageUrl(null);
          setFileIndicator(false);
          setErrorFileIndicator(true);
          setFileLoading(false);
          return;
        }

        // get secure url from our server
        const { url } = await fetch("/s3Url").then((res) => res.json());
        console.log(url);

        // post the image direclty to the s3 bucket
        await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "Image/jpeg",
          },
          body: file,
        });

        setImageUrl(url.split("?")[0]);

        setFileIndicator(true);
        setErrorFileIndicator(false);
        setFileLoading(false);
      };
    };
  };

  const onSubmit = (e) => {
    e.preventDefault();

    setAlbumId(Math.random().toString(36).substr(2, 9));
    console.log(albumId);

    if (
      text == "" ||
      releaseDate == null ||
      liveDate == null ||
      cLine == "" ||
      pLine == "" ||
      labelName == "" ||
      numTracks < 1 ||
      imageUrl == null ||
      primaryGenre == null ||
      secondaryGenre == null ||
      artists.length == 0 ||
      writers.length == 0 ||
      writers[0].legalName == "Legal Name" ||
      writers[0].legalName === ""
    ) {
      toast("One or more required fields left empty.");
    } else {
      setShowSong(true);
    }
  };

  // var songs = [];
  // for (var i = 0; i < numTracks; i++) {
  //   songs.push(
  // <div key={i}>
  //   <SongForm
  //     num={i + 1}
  //     trigger={triggerSongSubmit}
  //     increment={incrementCount}
  //     decrement={decrementCount}
  //     album={albumId}
  //     artists={artists}
  //     writers={writers}
  //     producers={producers}
  //   />
  // </div>
  //   );
  // }

  return (
    <div>
      <ToastContainer />
      {!showSong && (
        <section className="form">
          <h3>Album details</h3>
          <form onSubmit={onSubmit}>
            <div className="form-container">
              <div className="form-group">
                <label htmlFor="text">
                  Cover art <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="file"
                  name="image"
                  id="formupload"
                  onChange={handleImage}
                />
                <p style={{ fontSize: "16px" }}>
                  Artworks Requirements:
                  <br />
                  • JPEG / JPG files only
                  <br />• Must be 3000px x 3000px
                  <br />• Max file size 10MB
                </p>
                {fileIndicator && (
                  <h3 style={{ color: "#00FF00" }}>File selected!</h3>
                )}
                {errorFileIndicator && (
                  <h3 style={{ color: "#FF0000" }}>
                    File not accepted. Please select a valid file.
                  </h3>
                )}
                {fileLoading && (
                  <h3>
                    Uploading file...
                    <ClipLoader loading={fileLoading} size={50} />
                  </h3>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="text">
                  Title <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="text"
                  id="text"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
                <label htmlFor="text">
                  Primary Genre <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <Select
                  defaultValue={primaryGenre}
                  onChange={setPrimaryGenre}
                  options={genreOptions}
                />
                <div style={{ paddingBottom: "20px" }}></div>

                <label htmlFor="text">
                  Release Date <span style={{ color: "#bd0000" }}>*</span>
                </label>
                {/*<input
                  type="text"
                  name="releaseDate"
                  id="releaseDate"
                  value={releaseDate}
                  onChange={(e) => {
                    setReleaseDate(e.target.value);
                  }}
                />*/}
                <DatePicker
                  selected={releaseDate}
                  onChange={(date) => setReleaseDate(date)}
                />

                <label htmlFor="text">
                  ℗ Line <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="pLine"
                  id="pLine"
                  value={pLine}
                  onChange={(e) => {
                    setPLine(e.target.value);
                  }}
                />
                <label htmlFor="text">
                  Label Name <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="labelName"
                  id="labelName"
                  value={labelName}
                  onChange={(e) => {
                    setLabelName(e.target.value);
                  }}
                />
                {/*<img src={coverArt} style={{ width: "100px" }} />*/}
                <label htmlFor="text">
                  Number of Tracks <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="numTracks"
                  id="numTracks"
                  value={numTracks}
                  onChange={(e) => {
                    setNumTracks(e.target.value);
                    // handleNumSongsChange(e);
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="text">UPC</label>
                <input
                  type="text"
                  name="upc"
                  id="upc"
                  value={upc}
                  onChange={(e) => {
                    setUpc(e.target.value);
                  }}
                />
                <label htmlFor="text">
                  Secondary Genre <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <Select
                  defaultValue={secondaryGenre}
                  onChange={setSecondaryGenre}
                  options={secondaryGenreOptions}
                />
                <div style={{ paddingBottom: "20px" }}></div>
                <label htmlFor="text">
                  Live Date <span style={{ color: "#bd0000" }}>*</span>
                </label>
                {/*<input
                  type="text"
                  name="liveDate"
                  id="liveDate"
                  value={liveDate}
                  onChange={(e) => {
                    setLiveDate(e.target.value);
                  }}
                />*/}
                <DatePicker
                  selected={liveDate}
                  onChange={(date) => setLiveDate(date)}
                />
                <label htmlFor="text">
                  © Line <span style={{ color: "#bd0000" }}>*</span>
                </label>
                <input
                  type="text"
                  name="cLine"
                  id="cLine"
                  value={cLine}
                  onChange={(e) => {
                    setCLine(e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <h3 htmlFor="text" style={{ textAlign: "left" }}>
                  Artist(s) <span style={{ color: "#bd0000" }}>*</span>
                </h3>
                {artists.map((art, index) => (
                  <div
                    key={index}
                    className="goal"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <label>{art.artistName}</label>
                    <div>
                      <Popup
                        trigger={
                          <button
                            type="button"
                            style={{
                              fontSize: "20px",
                              background: "none",
                              border: "none",
                              paddingLeft: "5px",
                              paddingRight: "5px",
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            <FaEdit />
                          </button>
                        }
                        position="center"
                        className="my-popup"
                        modal
                      >
                        <div className="form-group">
                          {savedArtists && savedArtists.length > 0 && (
                            <div>
                              <label>Select saved artist:</label>
                              <Select
                                defaultValue={null}
                                onChange={(selected) => {
                                  handleArtistSelect(selected, index);
                                }}
                                options={artistOptions}
                              />
                            </div>
                          )}
                          <label htmlFor="text">
                            Role <span style={{ color: "#bd0000" }}>*</span>
                          </label>
                          <Select
                            defaultValue={art.role}
                            onChange={(selectedValue) => {
                              handleRoleChange(selectedValue, index);
                            }}
                            options={artistRoleOptions}
                          />
                          <div style={{ paddingBottom: "20px" }}></div>
                          <label>
                            Artist Name:{" "}
                            <span style={{ color: "#bd0000" }}>*</span>
                          </label>
                          <input
                            type="text"
                            value={art.artistName}
                            onChange={(e) => handleArtistNameChange(e, index)}
                          />

                          <label>
                            Spotify URI (e.g.
                            spotify:artist:6kw4tIoctSJkUveo6rkW9u):{" "}
                            {art.spotifyURI != "New Artist" &&
                              art.spotifyURI.length != 37 && (
                                <>
                                  <br />
                                  <span style={{ color: "red" }}>
                                    Please enter a valid Spotify URI or "New
                                    Artist"
                                  </span>
                                </>
                              )}
                          </label>
                          <input
                            type="text"
                            value={art.spotifyURI}
                            onChange={(e) => handleSpotifyURIChange(e, index)}
                          />
                          <label>
                            Apple Id (e.g. 1523332292, the numbers in your Apple
                            Music artist page):
                          </label>
                          <input
                            type="text"
                            value={art.appleId}
                            onChange={(e) => handleAppleIdChange(e, index)}
                          />
                        </div>
                        <button
                          type="button"
                          className="chill-btn"
                          onClick={() => saveArtist(art)}
                        >
                          Save Artist
                        </button>
                      </Popup>
                      <button
                        type="button"
                        onClick={() => deleteArtist(index)}
                        style={{
                          fontSize: "20px",
                          background: "none",
                          border: "none",
                          color: "#bd0000",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <FaTrashAlt />
                      </button>
                    </div>
                  </div>
                ))}
                <button type="button" className="chill-btn" onClick={addArtist}>
                  Add Artist <FaPlus />
                </button>
                <h3
                  htmlFor="text"
                  style={{ marginTop: "20px", textAlign: "left" }}
                >
                  Writer(s) <span style={{ color: "#bd0000" }}>*</span>
                </h3>
                {writers.map((writer, index) => (
                  <div
                    key={index}
                    //className="goal"
                    //style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <label>
                        Writer {index + 1} Name
                        <button
                          type="button"
                          onClick={() => deleteWriter(index)}
                          style={{
                            fontSize: "20px",
                            background: "none",
                            border: "none",
                            color: "#bd0000",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FaTrashAlt />
                        </button>
                        {writer.writerName.search(" ") == -1 && (
                          <>
                            <br />
                            <span style={{ color: "red" }}>
                              Please enter a first and last name for the writer.
                            </span>
                          </>
                        )}
                      </label>
                      <input
                        type="text"
                        value={writer.writerName}
                        onChange={(e) => handleWriterNameChange(e, index)}
                      />

                      <label>
                        Writer {index + 1} Legal Name{" "}
                        <span
                          // title="Required for distribution. Will not be shared."
                          style={{}}
                          onMouseEnter={() => setInfoHover(true)}
                          onMouseLeave={() => setInfoHover(false)}
                        >
                          <FaInfoCircle />
                        </span>
                        {infoHover && (
                          <div
                            style={{
                              position: "absolute",
                              zIndex: "100",
                              background: "#f9f9f9",
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              width: "200px",
                              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            Required for distribution. Will not be shared
                            publicly. Might be the same as the writer name.
                          </div>
                        )}
                      </label>
                      <input
                        type="text"
                        value={writer.legalName}
                        onChange={(e) => handleWriterLegalNameChange(e, index)}
                      />
                    </div>
                  </div>
                ))}
                <button type="button" className="chill-btn" onClick={addWriter}>
                  Add Writer <FaPlus />
                </button>
                <h3
                  htmlFor="text"
                  style={{ marginTop: "20px", textAlign: "left" }}
                >
                  Producer(s)
                </h3>
                {producers.map((producer, index) => (
                  <div
                    key={index}
                    //className="goal"
                    //style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {(producer.producerName.search(",") != -1 ||
                      producer.producerName.search("&") != -1) && (
                      <>
                        <br />
                        <span style={{ color: "red" }}>
                          Note: Please enter multiple producers as separate
                          names. Avoid using commas or & to separate names.
                        </span>
                      </>
                    )}
                    <div>
                      <label>
                        Producer {index + 1}{" "}
                        <button
                          type="button"
                          onClick={() => deleteProducer(index)}
                          style={{
                            fontSize: "20px",
                            background: "none",
                            border: "none",
                            color: "#bd0000",
                            paddingLeft: "5px",
                            paddingRight: "5px",
                            cursor: "pointer",
                          }}
                        >
                          <FaTrashAlt />
                        </button>
                      </label>
                      <input
                        type="text"
                        value={producer.producerName}
                        onChange={(e) => handleProducerNameChange(e, index)}
                      />
                    </div>
                  </div>
                ))}
                <button
                  type="button"
                  className="chill-btn"
                  onClick={addProducer}
                >
                  Add Producer <FaPlus />
                </button>
              </div>
            </div>
            <div className="form-group">
              <button
                className="btn btn-submit"
                type="submit"
                style={{ marginTop: "20px" }}
              >
                Next ↦
              </button>
            </div>
          </form>
        </section>
      )}
      {showSong && (
        <section>
          <h3>Song details</h3>
          {/* {songs} */}
          {Array.from({ length: numTracks }).map((_, index) => (
            // <SongForm
            //   key={index}
            //   index={index}
            //   songData={songs[index]}
            //   onSongChange={handleSongChange}
            // />
            <div key={index}>
              <SongForm
                // num={index + 1}
                // trigger={triggerSongSubmit}
                // increment={incrementCount}
                // decrement={decrementCount}
                // album={albumId}
                // artists={artists}
                // writers={writers}
                // producers={producers}
                songData={songs[index]}
                onSongChange={handleSongChange}
                index={index}
                key={index}
              />
            </div>
          ))}
          <button
            disabled={disableSubmit}
            className="btn btn-submit"
            onClick={async () => {
              if (1 == 1) {
                setDisableSubmit(true);
                // setTriggerSongSubmit(triggerSongSubmit + 1);

                // check if all songs have been filled out
                let validSongs = true;
                songs.forEach((song) => {
                  if (
                    song.songName == "" ||
                    song.isrc == "" ||
                    song.audioUrl == "" ||
                    song.audioUrl == null ||
                    song.artists.length == 0 ||
                    song.writers.length == 0 ||
                    song.producers.length == 0
                  ) {
                    validSongs = false;
                  }
                });
                if (validSongs == false) {
                  toast("One or more song fields left empty.");
                  setDisableSubmit(false);
                  return;
                }

                const primary = primaryGenre.value;
                let secondary = "None";
                if (secondaryGenre.value) {
                  secondary = secondaryGenre.value;
                }

                dispatch(
                  createGoal({
                    text,
                    releaseDate,
                    liveDate,
                    cLine,
                    pLine,
                    labelName,
                    numTracks,
                    upc,
                    status,
                    imageUrl,
                    albumId,
                    primary,
                    secondary,
                    accountEmail,
                    accountName,
                    artists,
                    writers,
                    producers,
                    isDistributed: false,
                  })
                );

                // go through each song and dispatch createSong
                songs.forEach((song, songIndex) => {
                  dispatch(
                    createSong({
                      songName: song.songName,
                      songNumber: song.songNumber,
                      isrc: song.isrc,
                      previewStartTime: song.previewStartTime
                        ? song.previewStartTime
                        : "None",
                      album: albumId,
                      artists: song.artists,
                      writers: song.writers,
                      producers: song.producers,
                      audioUrl: song.audioUrl,
                      payeeInfo: song.payeeInfo,
                      explicit: song.explicit,
                    })
                  );
                });

                toast(
                  "Album sent for review. You can view your album status by clicking on My Albums"
                );
              } else {
                toast("One or more song fields left empty.");
                setDisableSubmit(false);
              }
              //setTriggerSongSubmit(triggerSongSubmit + 1);
            }}
          >
            Submit album for review
          </button>
        </section>
      )}
    </div>
  );
}

export default GoalForm;
